import React from "react"
import accounting from "accounting"

import R from "ramda"
import slugify from "underscore.string/slugify"
import treatments from "data/treatments"
import Layout from "components/layout"
import SEO from "components/seo"

const categories = R.compose(R.map(R.prop("category")))(treatments)

const Prices = ({ prices }) => (
  <table className="col-12">
    {R.map(
      price => (
        <tr>
          {R.has("description", price) && (
            <td className="col-12 px0 border-none">
              {R.prop("description", price)}
            </td>
          )}
          {R.has("price", price) && (
            <td className="col-6 px0 border-none">
              <span className="block bold">
                {accounting.formatMoney(R.prop("price", price), "£", 0)}
              </span>
              {R.has("extras", price) && (
                <span className="block regular h6">
                  {R.prop("extras", price)}
                </span>
              )}
            </td>
          )}
          {R.has("duration", price) && (
            <td className="col-6 px0 border-none">
              {R.prop("duration", price)} mins
            </td>
          )}
        </tr>
      ),
      prices
    )}
  </table>
)

const Treatments = ({ treatments }) => (
  <div>
    {R.map(
      treatment => (
        <div className="sm-flex py2 border-bottom border-silver treatment">
          <div className="sm-col-6">
            <h1 className="h4 my0 regular">{R.prop("name", treatment)}</h1>
            {R.has("description", treatment) && (
              <p className="h6 my0 regular">
                {R.prop("description", treatment)}
              </p>
            )}
          </div>
          <div className="sm-col-6">
            <Prices prices={R.prop("prices", treatment)} />
          </div>
        </div>
      ),
      treatments
    )}
  </div>
)

export default () => (
  <Layout>
    <div className="content px2 py3 mx-auto bg-white treatments">
      <SEO
        title={`Treatments`}
        meta={[
          {
            name: "description",
            content:
              "The Surrey Health and Beauty Spa based in Westcroft Leisure Centre, Carshalton",
          },
          { name: "keywords", content: "surrey, health, beauty, spa" },
        ]}
      />

      <h1 className="h4 caps bold mt0 mb3 center">Treatments</h1>

      <div className="flex flex-wrap">
        {R.map(
          category => (
            <a
              className="mr1 mb1 h6 border border-silver p1 gray rounded"
              href={`#${slugify(category)}`}
            >
              {category}
            </a>
          ),
          categories
        )}
      </div>

      {R.map(
        ({ category, treatments }) => (
          <div className="mb3 lg-mb4" id={slugify(category)}>
            <h1 className="h2 regular mb3 flex justify-between items-center">
              <span>{category}</span>

              <a href="#top" className="h6">
                ↑ Back to top
              </a>
            </h1>
            <Treatments treatments={treatments} />
          </div>
        ),
        treatments
      )}
    </div>
  </Layout>
)
